import { useEffect, useState } from "react";
import useRequest from "./useRequest";

const useAllActiveColors = () => {
  const [colors, setColors] = useState([]);
  const { request: colorRequest, response: colorResponse } = useRequest();
  useEffect(() => {
    colorRequest("GET", "color/active");
  }, []);
  useEffect(() => {
    if (!colorResponse) return;
    let colorsList = colorResponse.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    setColors(colorsList);
  }, [colorResponse]);
  return { colors };
};

export default useAllActiveColors;
