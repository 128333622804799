import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate } from "../../util/fn";
import { BASEURL } from "../../constant/api";

const apiName = "product/product_by_sale";
//const titleSingular = "customer";
const titlePlural = "Trending Products";

const OBJ_TABLE = {
  "product Name": "product_name",
  SKU: "sku",
  type: "type",
  color: "color_name",
  size: "size_name",
  "category 1": "parent_category",
  "category 2": "category_level_2",
  "category 3": "category_level_3",
  "total sales": "sales_count",
  "total views": "view_count",
  "sales (score)": "sales_score",
  "page views (score)": "view_score",
  "final score": "final_score",
  //   "Price": "price",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  sku = "",
  product_name = "",
  sales_count = "",
  view_count = "",
  parent_category = "",
  category_level_2 = "",
  category_level_3 = "",
  export_excel = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "created_at";
    } else if (sortBy === "phone number") {
      sortBy = "contact";
    } else if (sortBy === "status") {
      sortBy = "isActive";
    } else if (sortBy === "updated on") {
      sortBy = "updated_at";
    }
  } else {
    sortBy = "created_at";
  }
  order = order.length > 0 ? order : "desc";

  //dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  //dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `${apiName}?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&sku=${sku}&product_name=${product_name}&parent_category=${parent_category}&category_level_2=${category_level_2}&category_level_3=${category_level_3}&sales_count=${sales_count}&view_count=${view_count}&export_excel=${export_excel}`;
};

const ViewAllTrending = () => {
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "updated on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);
  const [parentCategoryInput, setParentCategoryInput] = useState([]);

  const [categoryL2Input, setCategoryL2Input] = useState([]);
  const [categoryL3Input, setCategoryL3Input] = useState([]);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
    setValue,
  } = useForm();

  const { request, response } = useRequest();

  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestSendCreds, response: responseSendCreds } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  const { request: getParentCategory, response: parentCategoryResponse } =
    useRequest();
  const { request: getCategoryLevel2, response: categoryLevel2Response } =
    useRequest();
  const { request: getCategoryLevel3, response: categoryLevel3Response } =
    useRequest();

  useEffect(() => {
    getParentCategory("GET", `category/activeCategories?parent_id=${0}`);
  }, []);

  useEffect(() => {
    console.log("sss");
    if (!parentCategoryResponse) return;
    setParentCategoryInput(parentCategoryResponse.categories);
  }, [parentCategoryResponse]);

  const changeParentCategory = (value) => {
    setCategoryL2Input([]);
    setCategoryL3Input([]);
    setValue("category_level_2", "");
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel2("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL2 = (value) => {
    setCategoryL3Input([]);
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel3("GET", `category/activeCategories?parent_id=${value}`);
  };

  useEffect(() => {
    if (!categoryLevel2Response) return;

    setCategoryL2Input(categoryLevel2Response.categories);
  }, [categoryLevel2Response]);

  useEffect(() => {
    if (!categoryLevel3Response) return;

    setCategoryL3Input(categoryLevel3Response.categories);
  }, [categoryLevel3Response]);

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(1, 10, currentSort.sortBy, currentSort.order)
      );
    }
    document.title = `${titlePlural} - Single Vendor`;
  }, []);

  useEffect(() => {
    if (response) {
      console.log(response);
      setDataList(response.finalMergedProducts);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseSendCreds) {
      toast.success(responseSendCreds.message);
    }
  }, [responseSendCreds]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;
      const newDataList = [...dataList];
      const indexToChange = newDataList.findIndex((list) => list.id == id);
      newDataList[indexToChange].isActive = newStatus;
      newDataList[indexToChange].is_active = +newStatus;
      toast.success(responseChangeStatus.message);
      setDataList(newDataList);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newDataList = [...dataList];
      newDataList = newDataList.filter((list) => list.id != id);
      setDataList(newDataList);
      setTotalDocuments(totalDocuments - 1);
      toast.success(responseDelete.message);
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setDataList([]);
    const {
      sku,
      product_name,
      sales_count,
      view_count,
      parent_category,
      category_level_2,
      category_level_3,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        sku,
        product_name,
        sales_count,
        view_count,
        parent_category,
        category_level_2,
        category_level_3
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", `${apiName}`, { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const {
      sku,
      product_name,
      sales_count,
      view_count,
      parent_category,
      category_level_2,
      category_level_3,
    } = getValues();

    // if (dateFrom && dateTo) {
    //   if (Moment(dateFrom).isAfter(dateTo)) {
    //     setError("dateTo", {
    //       type: "manual",
    //     });
    //     return;
    //   }
    // }
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        sku,
        product_name,
        sales_count,
        view_count,
        parent_category,
        category_level_2,
        category_level_3
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("sku");
    resetField("product_name");
    resetField("parent_category");
    resetField("category_level_2");
    resetField("category_level_3");
    resetField("sales_count");
    resetField("view_count");
    resetField("sales_score");
    resetField("view_score");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      sku,
      product_name,
      sales_count,
      view_count,
      parent_category,
      category_level_2,
      category_level_3,
    } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        sku,
        product_name,
        sales_count,
        view_count,
        parent_category,
        category_level_2,
        category_level_3
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  //   const sortingHandler = (sortBy) => {
  //   const {
  //     product_id,
  //     product_name,
  //     sales_count,
  //     view_count,
  //     parent_category,
  //     category_level_2,
  //     category_level_3,
  //   } = getValues();

  //   if (currentSort.sortBy == sortBy) {
  //     const newOrder = currentSort.order === "asc" ? "desc" : "asc";
  //     request(
  //       "GET",
  //       searchQueryHandler(
  //         product_id,
  //         product_name,
  //         type,
  //         color_name,
  //         size_name,
  //         parent_category,
  //         category_level_2,
  //         category_level_3,
  //         sales_count,
  //         view_count,
  //         sales_score,
  //         view_score,
  //         final_score
  //       )
  //     );
  //     setCurrentSort({ sortBy, order: newOrder });
  //   } else {
  //     request(
  //       "GET",
  //       searchQueryHandler(
  //         page,
  //         perPage,
  //         sortBy,
  //         "desc",
  //         product_id,
  //         product_name,
  //         type,
  //         color_name,
  //         size_name,
  //         parent_category,
  //         category_level_2,
  //         category_level_3,
  //         sales_count,
  //         view_count,
  //         sales_score,
  //         view_score,
  //         final_score
  //       )
  //     );
  //     setCurrentSort({ sortBy, order: "desc" });
  //   }
  // };

  const changeStatusHandler = (id) => {
    const data = dataList.find((list) => list.id == id);
    const status = !data.isActive;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", `${apiName}/status`, {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };
  //   const sendCredentials = (id) => {
  //     requestSendCreds("POST", `${apiName}/send-credentials`, { id });
  //   };

  const InputFields = [
    {
      label: "Product Name",
      name: "product_name",
      required: false,
    },
    {
      label: "SKU",
      name: "sku",
      required: false,
    },
    {
      label: "Total Sales",
      name: "sales_count",
      required: false,
    },
    {
      label: "Total Page View",
      name: "view_count",
      required: false,
    },

    {
      isSelectInput: true,
      label: "Parent Category",
      name: "parent_category",
      required: false,
      onChange: changeParentCategory,
      children: (
        <>
          <option value={""}>All</option>,
          {parentCategoryInput.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 2",
      name: "category_level_2",
      required: false,
      onChange: changeCategoryL2,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL2Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 3",
      name: "category_level_3",
      required: false,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL3Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
  ];

  const downloadExcelSearchQuery = () => {
    const {
      sku,
      product_name,
      sales_count,
      view_count,
      parent_category,
      category_level_2,
      category_level_3,
    } = getValues();

    const path = searchQueryHandler(
      "",
      "",
      currentSort.sortBy,
      currentSort.order,
      sku,
      product_name,
      sales_count,
      view_count,
      parent_category,
      category_level_2,
      category_level_3,
      1
    );

    return path.split("?")[1];
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title={titlePlural}
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <a
                      href={`${
                        BASEURL.PORT
                      }/${apiName}?${downloadExcelSearchQuery()}`}
                      className="btn btn-primary mr-2"
                    >
                      Export
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      //sortingHandler={sortingHandler}
                      mainData={dataList}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      //   links={[
                      //     {
                      //       isLink: false,
                      //       name: "Deactivate",
                      //       click: changeStatusHandler,
                      //       title: "Click To Activate",
                      //       key: "2_3",
                      //     },
                      //     {
                      //       isLink: false,
                      //       name: "Activate",
                      //       click: changeStatusHandler,
                      //       title: "Click To Deactivate",
                      //       key: "2_3",
                      //     },
                      //     {
                      //       isLink: true,
                      //       to: "/customer/view",
                      //       name: "View",
                      //       extraData: true,
                      //       key: "2_4",
                      //     },
                      //     {
                      //       isLink: true,
                      //       to: "/customer/edit",
                      //       name: "Edit",
                      //       extraData: true,
                      //       key: "2_5",
                      //     },
                      //     {
                      //       isLink: false,
                      //       name: "Delete",
                      //       click: deleteHandler,
                      //       key: "2_6",
                      //     },
                      //     {
                      //       isLink: true,
                      //       to: "/customer/change-password",
                      //       name: "ChangePassword",
                      //       extraData: true,
                      //       title: "Change Password",
                      //       key: "2_7",
                      //     },

                      //     {
                      //       isLink: false,
                      //       name: "SendCreds",
                      //       click: sendCredentials,
                      //       title: "Send Credentials",
                      //       key: "2_8",
                      //     },
                      //   ]}
                      onlyDate={{
                        created_at: "date",
                        updated_at: "dateTimeWithSuffix",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      dontShowSort={[
                        "product ID",
                        "product Name",
                        "type",
                        "color",
                        "size",
                        "category 1",
                        "category 2",
                        "category 3",
                        "total sales",
                        "total views",
                        "sales (score)",
                        "page views (score)",
                        "final score",
                      ]}
                      renderAs={{
                        sku: (value) => {
                          return value || "-";
                        },
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={dataList.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllTrending;
