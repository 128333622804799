import { useEffect, useState } from "react";
import useRequest from "./useRequest";

const useAllActiveSizes = () => {
  const { request: sizeRequest, response: sizeResponse } = useRequest();
  const [sizes, setSizes] = useState([]);
  useEffect(() => {
    sizeRequest("GET", "size/active");
  }, []);

  useEffect(() => {
    if (!sizeResponse) return;
    let sizesList = sizeResponse.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    setSizes(sizesList);
  }, [sizeResponse]);
  return { sizes };
};

export default useAllActiveSizes;
