import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import {
  ReactSelectInputTwo,
  SearchInput,
  SearchSubmitButton,
} from "../Form/Form";
import { addOneToDate } from "../../util/fn";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { API } from "../../constant/api";
import { useParams } from "react-router";
import { OrderStatusObj } from "../../util/helper";

const statusUrls = [
  "/order/order_placed",
  "/order/pending_action",
  "/order/order_confirmed",
  "/order/dispatched",
  "/order/shipped",
  "/order/in_transit",
  "/order/delivered",
  "/order/Cancelled",
  "/order/Returned",
  "/order/all",
];

const apiName = "order";
const titlePlural = "Order Management";

const OBJ_TABLE = {
  "Order Details": `order_number`,
  "User Details": "order_address",
  "Order Date": "created_at",
};

export const SubTab = ({
  name,
  index,
  image,
  tabName,
  status,
  onClick = () => {},
}) => {
  const location = useLocation();
  const history = useHistory();
  const [newTab, setNewTab] = useState("/order/all");

  useEffect(() => {
    const decodedPathname = decodeURIComponent(location.pathname);
    const updatedPathname = decodedPathname.replace(/Shipped%20%20\(1\)/, "");

    setNewTab(updatedPathname);

    if (decodedPathname !== updatedPathname) {
      history.replace(updatedPathname);
    }
  }, [location.pathname, history]);

  const activeClass = statusUrls.findIndex((data, index) => {
    if (data == newTab) {
      return index == 0 ? true : 1;
    }
  });

  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <span
        className={`nav-link cursor-pointer ${
          index === activeClass ? "active" : ""
        }`}
        // to={`/order/${status}`}
        onClick={() => onClick(index)}
      >
        <>
          <span className="nav-text">{name}</span>
        </>
      </span>
    </li>
  );
};

function searchQueryHandler(
  page,
  per_page,
  sortBy,
  order,
  status,
  country = "",
  state = "",
  city = "",
  keyword = "",
  dateFrom = "",
  dateTo = "",
  order_status_query = ""
) {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";
  // dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  // dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());
  order_status_query = order_status_query || status || "all";
  return `page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&country=${country}&state=${state}&city=${city}&keyword=${keyword}&date_from=${dateFrom}&date_to=${dateTo}&order_status=${order_status_query}`;
}

// ${apiName}/all?

const ViewAll = () => {
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const location = useLocation();

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const setInitialCountryValue = useRef(false);
  const setInitialStateValue = useRef(false);
  const setInitialCityValue = useRef(false);

  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });

  const [showStatus, setShowStatus] = useState([
    {
      key: "order_placed",
      name: "Pending Orders",
    },
    {
      key: "pending_action",
      name: "Pending Action",
    },
    {
      key: "order_confirmed",
      name: "Order Confirmed",
    },
    {
      key: "dispatched",
      name: "Dispatched",
    },
    {
      key: "shipped",
      name: "Shipped",
    },
    {
      key: "in_transit",
      name: "In Transit",
    },

    {
      key: "delivered",
      name: "Delivered",
    },
    {
      key: "Cancelled",
      name: "Cancelled",
    },
    {
      key: "Returned",
      name: "Returned",
    },
    {
      key: "all",
      name: "All Orders",
    },
  ]);
  const [orderStatus, setOrderStatus] = useState("all");

  const { records_per_page, date_time_format } = useSelector(
    (state) => state.setting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      country: "",
      state: "",
      city: "",
    },
  });

  const history = useHistory();

  const { request, response } = useRequest();
  const { request: countryRequest, response: countryResponse } = useRequest();
  const { request: stateRequest, response: stateResponse } = useRequest();
  const { request: cityRequest, response: cityResponse } = useRequest();
  // console.log(location.pathname.split("/")[2]);

  const generateRequestQueryAndSetValues = (queryParams, currentTab) => {
    const page = queryParams.get("page") || 1;
    const per_page = queryParams.get("per_page") || records_per_page;
    const sort_by = queryParams.get("sort_by") || currentSort.sortBy;
    const order = queryParams.get("order") || currentSort.order;
    const keyword = queryParams.get("keyword") || "";
    const country = queryParams.get("country") || "";
    const state = queryParams.get("state") || "";
    const city = queryParams.get("city") || "";
    const dateFrom = queryParams.get("date_from") || "";
    const dateTo = queryParams.get("date_to") || "";
    const order_status_query = queryParams.get("order_status") || "";
    setValue("keyword", keyword);
    setValue("country", country);
    setValue("state", state);
    setValue("city", city);
    setValue("dateFrom", dateFrom);
    setValue("dateTo", dateTo);
    setValue("order_status_query", order_status_query);
    setPage(Number(page));
    setPerPage(Number(per_page));

    return searchQueryHandler(
      page,
      per_page,
      sort_by,
      order,
      currentTab,
      country,
      state,
      city,
      keyword,
      dateFrom,
      dateTo,
      order_status_query
    );
  };

  const updatePath = (queryPath) => {
    const currentPath = location.pathname;
    history.push(`${currentPath}?${queryPath}`);
  };

  useEffect(() => {
    if (!records_per_page) return;
    const queryParams = new URLSearchParams(location.search);

    request(
      "GET",
      `order/all?${generateRequestQueryAndSetValues(
        queryParams,
        location.pathname.split("/")[2]
      )}`
    );

    /* request(
      "GET",
      searchQueryHandler(
        1,
        records_per_page,
        currentSort.sortBy,
        currentSort.order,
        location.pathname.split("/")[2]
      )
    ); */
    setOrderStatus(location.pathname.split("/")[2]);
    countryRequest("GET", "shipping-country/data");

    document.title = `${titlePlural} - Single Vendor`;
  }, [records_per_page, location.search]);

  useEffect(() => {
    if (response) {
      setDataList(response.orders);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
      // setShowStatus(response.docsCount);
    }
  }, [response]);

  useEffect(() => {
    if (!countryResponse) return;
    let { countries } = countryResponse;
    countries = countries.map((country) => {
      return { id: country.id, name: JSON.parse(country?.countryName)["en"] };
    });

    setCountryList(countries);
  }, [countryResponse]);

  useEffect(() => {
    if (!countryList || !countryList.length) return;
    const queryParams = new URLSearchParams(location.search);
    const queryCountry = queryParams.get("country");
    if (queryCountry) {
      const searchSelectCountry = countryList.find(
        (country) => country.name == queryCountry
      );
      if (!searchSelectCountry) return;
      setValue("customCountry", JSON.stringify(searchSelectCountry));
      handleCountryChange(searchSelectCountry.id, searchSelectCountry.name);
    }
  }, [countryList]);

  useEffect(() => {
    if (!stateResponse) return;
    let { states } = stateResponse;
    states = states.map((state) => {
      return { id: state.id, name: JSON.parse(state?.stateName)["en"] };
    });
    setStateList(states);
  }, [stateResponse]);

  useEffect(() => {
    if (!stateList || !stateList.length) return;
    const queryParams = new URLSearchParams(location.search);
    const queryState = queryParams.get("state");
    if (queryState) {
      const searchSelectState = stateList.find(
        (state) => state.name == queryState
      );
      //console.log("ss", searchSelectState);
      if (!searchSelectState) return;
      setValue("customState", JSON.stringify(searchSelectState));
      handleStateChange(searchSelectState.id, searchSelectState.name);
    }
  }, [stateList]);

  useEffect(() => {
    if (!cityResponse) return;

    let { cities } = cityResponse;
    cities = cities.map((city) => {
      return { id: city.id, name: JSON.parse(city?.cityName)["en"] };
    });
    //console.log("cc", cities);
    setCityList(cities);
  }, [cityResponse]);

  useEffect(() => {
    if (!cityList || !cityList.length) return;
    const queryParams = new URLSearchParams(location.search);
    const queryCity = queryParams.get("city");
    if (queryCity) {
      const searchSelectCity = cityList.find((city) => city.name == queryCity);
      if (searchSelectCity) setValue("city", queryCity);
    }
  }, [cityList]);

  const fetchMoreData = ({ selected }) => {
    setDataList([]);
    setPage(selected + 1);
    const {
      keyword,
      country,
      state,
      city,
      dateFrom,
      dateTo,
      order_status_query,
    } = getValues();

    const queryPath = searchQueryHandler(
      selected + 1,
      perPage,
      currentSort.sortBy,
      currentSort.order,
      orderStatus,
      country,
      state,
      city,
      keyword,
      dateFrom,
      dateTo,
      order_status_query
    );

    updatePath(queryPath);
  };

  const onSearchHandler = (data) => {
    const {
      keyword,
      country,
      state,
      city,
      dateFrom,
      dateTo,
      order_status_query,
    } = getValues();

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }

    setPage(1);

    const queryPath = searchQueryHandler(
      1,
      perPage,
      currentSort.sortBy,
      currentSort.order,
      orderStatus,
      country,
      state,
      city,
      keyword,
      dateFrom,
      dateTo,
      order_status_query
    );
    updatePath(queryPath);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("country");
    resetField("customCountry");
    resetField("state");
    resetField("customState");
    resetField("city");
    resetField("keyword");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("order_status_query");

    //console.log(orderStatus, "orderStatus");
    setPage(1);
    const queryPath = searchQueryHandler(
      1,
      perPage,
      currentSort.sortBy,
      currentSort.order,
      orderStatus
    );

    //updatePath(queryPath);
    history.push("/order/all");
  };

  const perPageChangeHandler = (event) => {
    const {
      keyword,
      country,
      state,
      city,
      dateFrom,
      dateTo,
      order_status_query,
    } = getValues();

    setPage(1);
    setPerPage(event.target.value);
    const queryPath = searchQueryHandler(
      1,
      event.target.value,
      currentSort.sortBy,
      currentSort.order,
      orderStatus,
      country,
      state,
      city,
      keyword,
      dateFrom,
      dateTo,
      order_status_query
    );
    updatePath(queryPath);
  };

  const sortingHandler = (sortBy) => {
    const {
      keyword,
      country,
      state,
      city,
      dateFrom,
      dateTo,
      order_status_query,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      setCurrentSort({ sortBy, order: newOrder });
      const queryPath = searchQueryHandler(
        page,
        perPage,
        sortBy,
        newOrder,
        orderStatus,
        country,
        state,
        city,
        keyword,
        dateFrom,
        dateTo,
        order_status_query
      );
      updatePath(queryPath);
    } else {
      setCurrentSort({ sortBy, order: "desc" });
      const queryPath = searchQueryHandler(
        page,
        perPage,
        sortBy,
        "desc",
        orderStatus,
        country,
        state,
        city,
        keyword,
        dateFrom,
        dateTo,
        order_status_query
      );
      updatePath(queryPath);
    }
  };

  const searchingData = (statusData) => {
    const { keyword, country, state, city, dateFrom, dateTo } = getValues();
    resetField("order_status_query");

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }

    setOrderStatus(statusData);
    setPage(1);
    const queryPath = searchQueryHandler(
      1,
      perPage,
      currentSort.sortBy,
      currentSort.order,
      statusData,
      country,
      state,
      city,
      keyword,
      dateFrom,
      dateTo
    );
    history.push(`/order/${statusData}?${queryPath}`);
  };

  const handleCountryChange = (id, name) => {
    //console.log("con", country);
    setValue("state", "");
    setValue("city", "");
    setStateList([]);
    setCityList([]);
    stateRequest("GET", `state/data?country_id=${id}`);
    setValue("country", name);
  };

  const handleStateChange = (id, name) => {
    //console.log("st", state);
    setValue("city", "");
    setCityList([]);

    cityRequest("GET", `shipping-city/data?state_id=${id}`);
    setValue("state", name);
  };

  const InputFields = [
    {
      label: "Keyword",
      name: "keyword",
      required: false,
    },

    {
      isSelectInput: true,
      label: "Country",
      name: "customCountry",
      required: false,
      onChange: (value) => {
        //console.log(value);

        if (!value) return;
        const country = JSON.parse(value);
        handleCountryChange(country.id, country.name);
      },
      children: (
        <>
          <option value={""}>All</option>,
          {countryList.map((country) => (
            <option
              value={JSON.stringify({
                id: country.id,
                name: country.name,
              })}
            >
              {country.name}
            </option>
          ))}
        </>
      ),
    },

    {
      isSelectInput: true,
      label: "State",
      name: "customState",
      required: false,
      onChange: (value) => {
        if (!value) return;
        const state = JSON.parse(value);
        handleStateChange(state.id, state.name);
      },
      children: (
        <>
          <option value="">{"Select an option"}</option>
          {stateList.map((state) => (
            <option
              value={JSON.stringify({
                id: state.id,
                name: state.name,
              })}
            >
              {" "}
              {state.name}
            </option>
          ))}
        </>
      ),
    },

    {
      isSelectInput: true,
      label: "City",
      name: "city",
      required: false,
      children: (
        <>
          <option value="">{"Select an option"}</option>
          {cityList.map((city) => (
            <option value={city.name}> {city.name}</option>
          ))}
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  if (orderStatus == "all") {
    const field = {
      isSelectInput: true,
      label: "Order Status",
      name: "order_status_query",
      required: false,
      children: (
        <>
          <option value="">{"Select an option"}</option>
          {showStatus.map((status) => (
            <option key={status.key} value={status.key}>
              {status.name}
            </option>
          ))}
        </>
      ),
    };
    InputFields.push(field);
  }

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title={titlePlural}
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header card-header-tabs-line">
                  <div className="card-toolbar w-100">
                    <ul
                      className="nav flexNowrapTab nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                      role="tablist"
                    >
                      {showStatus.map((data, index) => (
                        <SubTab
                          key={index}
                          name={`${data.name}`}
                          index={index}
                          onClick={() => searchingData(data.key)}
                          status={data.key}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    {/* <CSVLink
                      data={dataList.map((s) => {
                        let data = {};

                        data["First Name"] = s.firstName;
                        data["Last Name"] = s.lastName;
                        data["Email"] = s.email;
                        data["Zip Code"] = s.zipCode;
                        data["Date of birth"] = Moment(s.dob).format(
                          "DD-MM-YYYY"
                        );
                        data["Created At"] = Moment(s.createdAt).format(
                          "DD-MM-YYYY"
                        );
                        data["Contact"] = s.contact;
                        data["Status"] = s.isActive;
                        data["Email Verified"] = s.isEmailVerified;

                        return data;
                      })}
                      filename={"customers.csv"}
                      className="btn btn-primary mr-2"
                      target="_blank"
                    >
                      Export
                    </CSVLink> */}
                    <a
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={dataList}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        {
                          isLink: true,
                          to: "/order/view",
                          name: "View",
                          extraData: true,
                          key: "7_2",
                        },
                      ]}
                      onlyDate={{
                        created_at: "dateTime",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      renderAs={{
                        order_number: (data, id, showData) =>
                          data ? (
                            <div>
                              <p>Order Id: {showData["order_number"]}</p>
                              {showData["delivery_fee"] ? (
                                <p>
                                  Delivery Fee: {showData[`currency`]}{" "}
                                  {showData["delivery_fee"]}
                                </p>
                              ) : (
                                ""
                              )}

                              {showData["customFee"] ? (
                                <p>
                                  Custom Fee:{showData[`currency`]}{" "}
                                  {showData["customFee"]}
                                </p>
                              ) : (
                                ""
                              )}

                              {showData["tax_amount"] ? (
                                <p>
                                  Tax: {showData[`currency`]}{" "}
                                  {showData["tax_amount"]}
                                </p>
                              ) : (
                                ""
                              )}

                              {showData["sub_total"] ? (
                                <p>
                                  Sub Total: {showData["sub_total"]}{" "}
                                  {showData[`currency`]}
                                </p>
                              ) : (
                                ""
                              )}

                              {showData["total"] ? (
                                <p>
                                  Total: {showData["total"]}{" "}
                                  {showData[`currency`]}
                                </p>
                              ) : (
                                ""
                              )}

                              {showData["offerDiscount"] ? (
                                <p>
                                  Offer & Discounts: -
                                  {showData["offerDiscount"]}{" "}
                                  {showData[`currency`]}
                                  {showData["offers"] && (
                                    <p className="offer-title">
                                      ({showData["offers"]})
                                    </p>
                                  )}
                                </p>
                              ) : (
                                ""
                              )}

                              {showData["latest_status"] ? (
                                <p>
                                  Order Status :{" "}
                                  {OrderStatusObj[showData["latest_status"]]}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                              {/* <p>Product Name: {showData["name"]}</p> */}
                              {/* <p>Quantity: {showData["itemQuantity"]}</p> */}
                            </div>
                          ) : (
                            "-"
                          ),
                        order_address: (data, id, showData) => {
                          const orderAddress = data ? JSON.parse(data) : {};
                          return data ? (
                            <>
                              {/* <div>
                              {showData[`email`] ? (
                                <p>Email: {showData[`email`]}</p>
                              ) : (
                                ""
                              )}
                              {showData[`full_name`] ? (
                                <p>Name: {showData["full_name"]}</p>
                              ) : (
                                ""
                              )}
                              {showData[`phone_number`] &&
                              showData["phone_number_code"] ? (
                                <p>
                                  Phone: +{" "}
                                  {`${showData["phone_number_code"]}-${showData["phone_number"]}`}
                                </p>
                              ) : (
                                ""
                              )}

                              {showData[`country`] ? (
                                <p>Country: {showData["country"]}</p>
                              ) : (
                                ""
                              )}
                              {showData[`state`] ? (
                                <p>State: {showData["state"]}</p>
                              ) : (
                                ""
                              )}
                              {showData[`city`] ? (
                                <p>City: {showData["city"]}</p>
                              ) : (
                                ""
                              )}
                            </div> */}
                              {
                                <div>
                                  <p>
                                    Name:{" "}
                                    {orderAddress?.first_name +
                                      " " +
                                      orderAddress?.last_name}
                                  </p>

                                  <p>
                                    Phone: +{orderAddress?.phone_number_code}-
                                    {orderAddress?.phone_number}
                                  </p>

                                  <p>
                                    Address: {orderAddress?.address_1},{" "}
                                    {orderAddress?.address_2
                                      ? orderAddress?.address_2
                                      : ""}
                                  </p>

                                  <p>City: {orderAddress?.city}</p>
                                  <p>State: {orderAddress?.state}</p>

                                  <p>Country: {orderAddress?.country}</p>
                                </div>
                              }
                            </>
                          ) : (
                            "-"
                          );
                        },
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={dataList.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
