import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { View } from "../../util/Svg";
import Modal from "react-modal";
const OBJ_TABLE = {
  name: "name",
  subject: "subject",
  view: "body",
  "created on": "created_at",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "auto",
    padding: "0",
  },
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  name = "",
  subject = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "created on") {
      sortBy = "created_at";
    }
  } else {
    sortBy = "created_at";
  }

  order = order.length > 0 ? order : "desc";

  return `marketing/all?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&name=${name}&subject=${subject}`;
};

const ViewAll = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
  } = useForm();

  const { request, response } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Email- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setData(response.result);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setData([]);
    //name which is enter on search input
    const { name, subject } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        subject
      )
    );
  };

  const onSearchHandler = (data) => {
    const { name, subject } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        subject
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("name");
    resetField("subject");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { name, subject } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        name,
        subject
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { name, subject } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, newOrder, name, subject)
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, "desc", name, subject)
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  //search input field
  const InputFields = [
    {
      label: "Name",
      name: "name",
      required: false,
    },
    {
      label: "Subject",
      name: "subject",
      required: false,
    },
  ];

  return (
    <>
      <div
        className="content  d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <Breadcrumb
          title="Email Marketing"
          links={[{ to: "/", name: "Dashboard" }]}
        />

        <div className="d-flex flex-column-fluid">
          <div className=" container ">
            <div className="row">
              <div className="col-12">
                <div className="card card-custom card-stretch card-shadowless">
                  <div className="card-header">
                    <div className="card-title"></div>
                    <div className="card-toolbar">
                      <a
                        /*href="#!"*/
                        className="btn btn-primary dropdown-toggle mr-2"
                        data-toggle="collapse"
                        data-target="#collapseOne6"
                      >
                        Search
                      </a>

                      <Link
                        to="/email-marketing/add"
                        className="btn btn-primary"
                      >
                        Add New Email
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="accordion accordion-solid accordion-toggle-plus"
                      id="accordionExample6"
                    >
                      <div
                        id="collapseOne6"
                        className="collapse"
                        data-parent="#accordionExample6"
                      >
                        <div>
                          <form
                            onSubmit={handleSubmit(onSearchHandler)}
                            className="kt-form kt-form--fit mb-0"
                          >
                            <div className="row mb-6">
                              {InputFields.map((inputMain, index) => (
                                <SearchInput
                                  key={index}
                                  {...inputMain}
                                  errors={errors}
                                  register={register}
                                />
                              ))}
                            </div>

                            <SearchSubmitButton
                              handleSubmit={handleSubmit}
                              onSearchHandler={onSearchHandler}
                              onResetHandler={onResetHandler}
                            />
                          </form>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div className="dataTables_wrapper faq-table">
                      <Table
                        currentSort={currentSort}
                        sortingHandler={sortingHandler}
                        mainData={data}
                        tableHeading={Object.keys(OBJ_TABLE)}
                        tableData={Object.values(OBJ_TABLE)}
                        links={[
                          {
                            isLink: true,
                            to: "/email-marketing",
                            name: "View",
                            extraData: true,
                            key: "12_43",
                          },
                        ]}
                        onlyDate={{
                          created_at: "date",
                          startDate: "dateTime",
                          endDate: "dateTime",
                        }}
                        dontShowSort={["question", "answer"]}
                        renderAs={{
                          body: (value) => {
                            return (
                              <span
                                onClick={() => {
                                  setModalContent(value);
                                  setShowModal(true);
                                }}
                                className="svg-icon svg-icon-md svg-icon-primary cursor-pointer"
                              >
                                <View />
                              </span>
                            );
                          },
                        }}
                      />

                      {perPage !== 0 && (
                        <Pagination
                          page={page}
                          totalDocuments={totalDocuments}
                          getNewData={fetchMoreData}
                          perPage={perPage}
                          defaultPerPage={records_per_page}
                          perPageChangeHandler={perPageChangeHandler}
                          currentDocLength={data.length}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={customStyles}
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="messageModalLabel">
              Description
            </h5>
          </div>
          <div class="modal-body">
            <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewAll;
