import { useSelector } from "react-redux";
import { getValueInLanguage, isArrayNotBlank } from "../util/helper";
import useRequest from "./useRequest";
import { useEffect } from "react";

const useCategoryForm = () => {
  const { languages } = useSelector((state) => state.setting);
  const getNewFormData = (data) => {
    const formData = new FormData();
    if (data["image"] && data["image"][0])
      formData.append("image", data["image"][0]);
    if (data["og_image-en"] && data["og_image-en"][0])
      formData.append("og_image-en", data["og_image-en"][0]);
    if (data["og_image-ar"] && data["og_image-ar"][0])
      formData.append("og_image-ar", data["og_image-ar"][0]);
    if (isArrayNotBlank(data.colors))
      formData.append("colors", JSON.stringify(data.colors));
    if (isArrayNotBlank(data.sizes))
      formData.append("sizes", JSON.stringify(data.sizes));

    const subData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      const subDataEle = {
        language_id: id,
        lang_code: code,
        name: data[`name-${code}`],
        title: data[`title-${code}`],
        meta_description: data[`meta_description-${code}`],
        meta_keywords: data[`meta_keywords-${code}`],
        twitter_card: data[`twitter_card-${code}`],
        twitter_site: data[`twitter_site-${code}`],
        og_url: data[`og_url-${code}`],
        og_type: data[`og_type-${code}`],
        og_title: data[`og_title-${code}`],
        og_description: data[`og_description-${code}`],
        meta_chronological: data[`meta_chronological-${code}`],
      };
      subData.push(subDataEle);
    }

    formData.append("subData", JSON.stringify(subData));
    return formData;
  };

  const getEditFormData = (data, langDataIds) => {
    const formData = new FormData();

    if (data["image"] && data["image"][0])
      formData.append("image", data["image"][0]);

    if (data["og_image-en"] && data["og_image-en"][0])
      formData.append("og_image-en", data["og_image-en"][0]);
    if (data["og_image-ar"] && data["og_image-ar"][0])
      formData.append("og_image-ar", data["og_image-ar"][0]);

    formData.append("colors", JSON.stringify(data.colors));
    formData.append("sizes", JSON.stringify(data.sizes));

    const subData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      const subDataEle = {
        language_id: id,
        lang_code: code,
        id: langDataIds[code],
        name: data[`name-${code}`],
        title: data[`title-${code}`],
        meta_description: data[`meta_description-${code}`],
        meta_keywords: data[`meta_keywords-${code}`],
        twitter_card: data[`twitter_card-${code}`],
        twitter_site: data[`twitter_site-${code}`],
        og_url: data[`og_url-${code}`],
        og_type: data[`og_type-${code}`],
        og_title: data[`og_title-${code}`],
        og_description: data[`og_description-${code}`],
        meta_chronological: data[`meta_chronological-${code}`],
      };

      subData.push(subDataEle);
    }
    formData.append("subData", JSON.stringify(subData));
    return formData;
  };

  const initializeFormData = ({
    responseGetSection,
    setImage,
    setOgImageEn,
    setOgImageAr,
    setLangDataIds,
    reset,
  }) => {
    const { category: sectionData } = responseGetSection;
    let languageDatas = sectionData.languageData;
    const resetObj = {};
    const langDataIdsObj = {};
    setImage(sectionData.image);
    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      const currValue = getValueInLanguage(languageDatas, id);
      resetObj[`name-${code}`] = currValue.name;
      resetObj[`title-${code}`] = currValue.title;
      resetObj[`meta_description-${code}`] = currValue.meta_description;
      resetObj[`meta_keywords-${code}`] = currValue.meta_keywords;
      resetObj[`twitter_card-${code}`] = currValue.twitter_card;
      resetObj[`twitter_site-${code}`] = currValue.twitter_site;
      resetObj[`og_url-${code}`] = currValue.og_url;
      resetObj[`og_type-${code}`] = currValue.og_type;
      resetObj[`og_title-${code}`] = currValue.og_title;
      resetObj[`og_description-${code}`] = currValue.og_description;
      resetObj[`meta_chronological-${code}`] = currValue.meta_chronological;
      if (code == "en") setOgImageEn(currValue.og_image);
      if (code == "ar") setOgImageAr(currValue.og_image);
      langDataIdsObj[code] = currValue.id;
    }
    const colorInitalValues = responseGetSection.colors.map((color) => {
      return { label: color.name, value: color.id };
    });
    resetObj.colors = colorInitalValues;

    const sizeInitalValues = responseGetSection.sizes.map((size) => {
      return { label: size.name, value: size.id };
    });
    resetObj.sizes = sizeInitalValues;

    setLangDataIds(langDataIdsObj);
    reset(resetObj);
  };

  return { getNewFormData, getEditFormData, initializeFormData };
};

export default useCategoryForm;
