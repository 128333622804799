import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { View } from "../../util/Svg";
import Modal from "react-modal";
import moment from "moment";
const OBJ_TABLE = {
  email: "email",
  "notification sent on": "schedule_time",
};

const ViewAll = (props) => {
  const { id: marketingId } = props.match.params;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(1);

  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
  } = useForm();

  const { request, response } = useRequest();

  const searchQueryHandler = (page, per_page, sortBy, order, email = "") => {
    if (sortBy.length > 0) {
      if (sortBy == "created on") {
        sortBy = "created_at";
      }
    } else {
      sortBy = "created_at";
    }

    order = order.length > 0 ? order : "desc";

    return `marketing/users?marketing_id=${marketingId}&page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&email=${email}`;
  };

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Email- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setData(response.result);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setData([]);
    //email which is enter on search input
    const { email } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        email
      )
    );
  };

  const onSearchHandler = (data) => {
    const { email } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        email
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("email");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { email } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        email
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { email } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, newOrder, email)
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request("GET", searchQueryHandler(page, perPage, sortBy, "desc", email));
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  //search input field
  const InputFields = [
    {
      label: "Email",
      name: "email",
      required: false,
    },
  ];

  return (
    <>
      <div
        className="content  d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <Breadcrumb
          title="Notification"
          links={[
            { to: "/", name: "Dashboard" },
            { to: "/email-marketing", name: "Email Marketing" },
          ]}
        />

        <div className="d-flex flex-column-fluid">
          <div className=" container ">
            <div className="row">
              <div className="col-12">
                <div className="card card-custom card-stretch card-shadowless">
                  <div className="card-header">
                    <div className="card-title"></div>
                    <div className="card-toolbar">
                      <a
                        /*href="#!"*/
                        className="btn btn-primary dropdown-toggle mr-2"
                        data-toggle="collapse"
                        data-target="#collapseOne6"
                      >
                        Search
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="accordion accordion-solid accordion-toggle-plus"
                      id="accordionExample6"
                    >
                      <div
                        id="collapseOne6"
                        className="collapse"
                        data-parent="#accordionExample6"
                      >
                        <div>
                          <form
                            onSubmit={handleSubmit(onSearchHandler)}
                            className="kt-form kt-form--fit mb-0"
                          >
                            <div className="row mb-6">
                              {InputFields.map((inputMain, index) => (
                                <SearchInput
                                  key={index}
                                  {...inputMain}
                                  errors={errors}
                                  register={register}
                                />
                              ))}
                            </div>

                            <SearchSubmitButton
                              handleSubmit={handleSubmit}
                              onSearchHandler={onSearchHandler}
                              onResetHandler={onResetHandler}
                            />
                          </form>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div className="dataTables_wrapper faq-table">
                      <Table
                        currentSort={currentSort}
                        sortingHandler={sortingHandler}
                        mainData={data}
                        tableHeading={Object.keys(OBJ_TABLE)}
                        tableData={Object.values(OBJ_TABLE)}
                        onlyDate={{
                          created_at: "date",
                          startDate: "dateTime",
                          endDate: "dateTime",
                          schedule_time: "date",
                        }}
                        dontShowSort={["question", "answer"]}
                      />

                      {perPage !== 0 && (
                        <Pagination
                          page={page}
                          totalDocuments={totalDocuments}
                          getNewData={fetchMoreData}
                          perPage={perPage}
                          defaultPerPage={records_per_page}
                          perPageChangeHandler={perPageChangeHandler}
                          currentDocLength={data.length}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAll;
