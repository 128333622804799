import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  CKEditorInput,
  SelectInput,
  RenderInputFields,
  SubmitButton,
  ReactSelectInputTwo,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    control,
  } = useForm();

  const ckEditorRef = useRef();

  //   const [emailConstants, setEmailConstants] = useState([]);

  const { response, request } = useRequest();
  const { request: subscriberUsersReq, response: subscriberUsersRes } =
    useRequest();
  const [subscriberUsers, setSubscribeUsers] = useState([]);

  const history = useHistory();

  useEffect(() => {
    register("body", { required: true });
    subscriberUsersReq("GET", "marketing/subscribed-users");
    document.title = "Add Email Template - Single Vendor";
  }, []);

  useEffect(() => {
    register(`body`, { required: true });
  }, []);

  useEffect(() => {
    if (!subscriberUsersRes) return;
    setSubscribeUsers(subscriberUsersRes.users);
  }, [subscriberUsersRes]);

  useEffect(() => {
    if (response) {
      toast.success("Marketing Email has been added successfully.");
      history.push("/email-marketing");
    }
  }, [response]);

  const insertConstantHandler = () => {
    const constant = getValues("constant");
    if (constant.length == 0) {
      return;
    }
    // ckEditorRef.current.model.change((writer) => {
    //   writer.insertText(
    //     `{${constant}}`,
    //     ckEditorRef.current.model.document.selection.getFirstPosition()
    //   );
    // });
    ckEditorRef.current.editor.insertText(`{${constant}}`);
  };

  const selectAllSubScribedUser = () => {
    setValue("users", subscriberUsers);
  };

  const handleonInstanceReady = (editor) => {
    ckEditorRef.current = editor;
  };

  const onSubmit = (data) => {
    console.log("dd", data);
    const payload = {
      name: data.name,
      subject: data.subject,
      body: data.body,
      users: data?.users?.map((user) => user.value) || [],
      schedule_time: data.schedule_time,
    };
    request("POST", "marketing", payload);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Subject",
        type: "text",
        name: "subject",
        registerFields: {
          required: true,
        },
      },
    ],
    [
      {
        Component: SelectInput,
        label: "Constants",
        name: "constant",
        registerFields: {},
        children: (
          <>
            <option value="">{"Select Constant"}</option>
            <option key={"EMAIL"} value={"EMAIL"}>
              {"EMAIL"}
            </option>
          </>
        ),
        moreData: (
          <button
            onClick={insertConstantHandler}
            type="button"
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
          >
            Insert
          </button>
        ),
      },
      {
        Component: ReactSelectInputTwo,
        label: "Users",
        name: "users",
        registerFields: {
          required: true,
        },
        options: subscriberUsers,
        isMultiple: true,
        control: control,
        moreData: (
          <button
            onClick={selectAllSubScribedUser}
            type="button"
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4 my-5"
          >
            Select All
          </button>
        ),
      },
      {
        Component: Input,
        label: "Schedule Time",
        name: "schedule_time",
        isDate: true,

        registerFields: {
          required: true,
        },
        clearErrors,
        control,
        type: "datetime-local",
        min: new Date().toISOString().slice(0, 16),
        handleDateChange: () => {},
      },
    ],

    [
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Email Body",
        name: "body",
        registerFields: {
          required: true,
        },
        getValues,
        setValue,
        trigger,
        handleonInstanceReady: handleonInstanceReady,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: false,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Email Marketing"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/email-marketing", name: "Email Marketing" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Marketing Email
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
