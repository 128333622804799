import { useEffect } from "react";
import { API } from "../../../constant/api";
import useRequest from "../../../hooks/useRequest";
import "../product.css";
import { toast } from "react-toastify";
import { checkImageValidatiy } from "../../../util/helper";

const TempEditImageUploader = ({
  productId,
  images,
  setImages,
  coverImage,
  setCoverImage,
}) => {
  const { request, response } = useRequest();
  const { request: deleteImageRequest, response: deleteImageResponse } =
    useRequest();
  const {
    request: requestUpdateCoverImage,
    response: responseUpdateCoverImage,
  } = useRequest();

  const maxLength = 10;
  /* console.log("img", images);
  console.log("po", API.PORT); */

  // Process the files that are dropped or selected
  const processFiles = (files) => {
    const newImages = [];
    console.log("files", files);

    files.forEach((file) => {
      if (!file.type.match("image.*")) return; // Check if file is an image
      if (newImages.length >= maxLength) return; // Limit to maxLength
      if (!checkImageValidatiy(file)) return;
      newImages.push(file);
    });

    if (!coverImage && newImages.length > 0) setCoverImage(newImages[0]);

    const formData = new FormData();
    newImages.forEach((image) => {
      formData.append("images", image);
    });

    request("POST", `product/${productId}/product-images`, formData);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    processFiles(files);
  };

  const handleDrop = (event) => {
    //console.log("in drop");
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    //console.log("ff", files);
    if (files && files.length) processFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // Remove an image
  const handleImageRemove = (deleteImage) => {
    if (coverImage && coverImage === deleteImage) {
      return toast.error("Can't delete cover photo");
    }
    const newImages = [...images].filter((file) => file !== deleteImage);
    setImages(newImages);
    const payload = { deleteImage: deleteImage };
    deleteImageRequest(
      "DELETE",
      `product/${productId}/product-images`,
      payload
    );
  };

  // Set cover image
  const makeCoverImage = (file) => {
    setCoverImage(file);
    requestUpdateCoverImage("PUT", `product/${productId}/update-cover-image`, {
      new_cover_image: file,
      old_cover_image: coverImage,
    });
  };

  // Fetch images on component mount
  useEffect(() => {
    if (!response) return;
    setImages([coverImage, ...response?.images?.map((img) => img.image)]);
  }, [response]);

  return (
    <div
      className="upload__box"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="upload__btn-box">
        <label className="upload__btn">
          <p>Drag and drop or Upload from your computer</p>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="upload__inputfile"
            data-max_length={maxLength}
          />
        </label>
      </div>
      <div className="upload__img-wrap">
        {images.map((image, index) => {
          const imageUrl = `'${API.PORT}/${image}'`;

          return (
            <div key={index} className="upload__img-box">
              <div
                style={{ backgroundImage: `url(${imageUrl})` }}
                className="img-bg"
              >
                {coverImage === image && (
                  <h3 className="cover_image_add image_coverdesgin">
                    Cover Photo
                  </h3>
                )}

                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Make cover photo"
                  className="upload__img-icon upload__img-cover"
                  onClick={() => makeCoverImage(image)}
                ></div>
                <div
                  className="upload__img-icon upload__img-close"
                  onClick={() => handleImageRemove(image)}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TempEditImageUploader;
