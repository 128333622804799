import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Pagination from "../../Pagination/Pagination";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Table from "../../Table/Table";
import { SearchInput, SearchSubmitButton, SelectInput } from "../../Form/Form";
import { addOneToDate } from "../../../util/fn";
import { BASEURL } from "../../../constant/api";
import { COMMON_CURRENCY, stockMovementType } from "../../../util/helper";
import Moment from "moment";
import { OrderSvg, SaleSvg, UnitsSoldSvg } from "../../../util/Svg";

const api_path = "report/sales-summary";

const searchQueryHandler = (
  dateFrom = "1970-01-01",
  dateTo,
  export_excel = ""
) => {
  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `${api_path}?&dateFrom=${dateFrom}&dateTo=${dateTo}&export_excel=${export_excel}`;
};

const ViewAll = () => {
  const [result, setResult] = useState();
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request("GET", searchQueryHandler());
    }
    document.title = "Summary- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      //console.log("res", response.products);
      setResult(response.data);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  const onSearchHandler = (data) => {
    const { dateFrom, dateTo } = getValues();

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }

    request("GET", searchQueryHandler(dateFrom, dateTo));
    setResult();
    setPage(1);
  };

  const onResetHandler = (e) => {
    setResult();
    e.preventDefault();
    resetField("dateFrom");
    resetField("dateTo");

    request("GET", searchQueryHandler());
    setPage(1);
  };

  /* const sortingHandler = (sortBy) => {
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      discount_price,
      dateFrom,
      dateTo,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  }; */

  const downloadExcelSearchQuery = () => {
    const { dateFrom, dateTo } = getValues();

    const path = searchQueryHandler(
      "",
      "",
      currentSort.sortBy,
      currentSort.order,
      dateFrom,
      dateTo,
      1
    );

    return path.split("?")[1];
  };

  //search input field
  const InputFields = [
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  //console.log(products, "products");

  if (!result) return <></>;

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Sales Summary"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>
                    <a
                      href={`${
                        BASEURL.PORT
                      }/${api_path}?${downloadExcelSearchQuery()}`}
                      className="btn btn-primary mr-2"
                    >
                      Export
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <div className="row">
                      <div className="col-xl-4">
                        <Link
                          to={"/order/all"}
                          className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                        >
                          <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                              <OrderSvg />
                            </span>

                            <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                              {result.total_orders}
                            </div>
                            <div className="font-weight-bold text-inverse-danger font-size-sm">
                              Total Orders
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xl-4">
                        <Link
                          to={"/order/all"}
                          className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                        >
                          <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                              <SaleSvg />
                            </span>

                            <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                              {COMMON_CURRENCY} {result.total_sales_amount}
                            </div>
                            <div className="font-weight-bold text-inverse-danger font-size-sm">
                              Total Sales
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xl-4">
                        <Link
                          to={"/order/all"}
                          className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                        >
                          <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                              <SaleSvg />
                            </span>

                            <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                              {COMMON_CURRENCY} {result.total_revenue}
                            </div>
                            <div className="font-weight-bold text-inverse-danger font-size-sm">
                              Total Revenue
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xl-4">
                        <Link
                          to={"/order/all"}
                          className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                        >
                          <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                              <OrderSvg />
                            </span>

                            <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                              {COMMON_CURRENCY}{" "}
                              {Math.round(result.avg_order_value)}
                            </div>
                            <div className="font-weight-bold text-inverse-danger font-size-sm">
                              Average Order Value
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="col-xl-4">
                        <Link
                          to={"/order/all"}
                          className="card card-custom bg-danger bg-hover-state-danger card-stretch gutter-b"
                        >
                          <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ml-n1">
                              <UnitsSoldSvg />
                            </span>

                            <div className="text-inverse-danger font-weight-bolder font-size-h3 mb-2 mt-5">
                              {result.total_product_sold}
                            </div>
                            <div className="font-weight-bold text-inverse-danger font-size-sm">
                              Total Units Sold
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="dataTables_wrapper">
                          <div className="table-responsive" id="varianttable">
                            <h6>Top Selling Products With Variants</h6>
                            <table className="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center">
                              <thead>
                                <tr className="text-uppercase">
                                  <th
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <span href="javascript:void(0);">
                                      Product Name
                                    </span>
                                  </th>
                                  <th
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <span href="javascript:void(0);">
                                      Color
                                    </span>
                                  </th>
                                  <th
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <span href="javascript:void(0);">Size</span>
                                  </th>
                                  <th
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <span href="javascript:void(0);">
                                      Quantity
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {result?.topFiveVariantsSold.map((product) => (
                                  <tr>
                                    <td>{product.product}</td>
                                    <td>{product.color}</td>
                                    <td>{product.size}</td>
                                    <td>{product.quantity_sold}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="dataTables_wrapper">
                          <div className="table-responsive" id="varianttable">
                            <h6>Top Selling Products</h6>
                            <table className="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center">
                              <thead>
                                <tr className="text-uppercase">
                                  <th
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <span href="javascript:void(0);">
                                      Product Name
                                    </span>
                                  </th>

                                  <th
                                    style={{
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <span href="javascript:void(0);">
                                      Quantity
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {result?.topFiveProductsSold.map((product) => (
                                  <tr>
                                    <td>{product.product}</td>
                                    <td>{product.quantity_sold}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
