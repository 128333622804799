import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../../hooks/useRequest";
import Pagination from "../../Pagination/Pagination";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Table from "../../Table/Table";
import { SearchInput, SearchSubmitButton, SelectInput } from "../../Form/Form";
import { addOneToDate } from "../../../util/fn";
import { BASEURL } from "../../../constant/api";
import { COMMON_CURRENCY, stockMovementType } from "../../../util/helper";
import Moment from "moment";

const api_path = "report/total-discount-applied";

const OBJ_TABLE = {
  "Customer Id": "customer_id",
  "Customer Name": "customer_name",
  "Total Coupon Discounts Applied": "total_discount",
  "Total Orders with Discount": "coupon_count",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  customer_id = "",
  customer_name = "",
  total_discount = "",
  coupon_count = "",
  export_excel = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "created on") {
      sortBy = "created_at";
    }
    if (sortBy == "category") {
      sortBy = "categoryHierarchy";
    }

    if (sortBy == "status") sortBy = "is_active";
  } else {
    sortBy = "created_at";
  }

  order = order.length > 0 ? order : "desc";
  //dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  //dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `${api_path}?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&customer_id=${customer_id}&customer_name=${customer_name}&total_discount=${total_discount}&coupon_count=${coupon_count}&export_excel=${export_excel}`;
};

const ViewAll = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Product- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      //console.log("res", response.products);
      setData(response.data);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setData([]);
    //product which is enter on search input
    const { customer_id, customer_name, total_discount, coupon_count } =
      getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        customer_id,
        customer_name,
        total_discount,
        coupon_count
      )
    );
  };

  const onSearchHandler = (data) => {
    const { customer_id, customer_name, total_discount, coupon_count } =
      getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        customer_id,
        customer_name,
        total_discount,
        coupon_count
      )
    );
    setData([]);
    setPage(1);
  };

  const onResetHandler = (e) => {
    setData([]);
    e.preventDefault();
    resetField("customer_id");
    resetField("customer_name");
    resetField("total_discount");
    resetField("coupon_count");

    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    setData([]);
    const { customer_id, customer_name, total_discount, coupon_count } =
      getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        customer_id,
        customer_name,
        total_discount,
        coupon_count
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  /* const sortingHandler = (sortBy) => {
    const {
      product,
      parent_category,
      category_level_2,
      category_level_3,
      product_id,
      discount_price,
      dateFrom,
      dateTo,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          product,
          parent_category,
          category_level_2,
          category_level_3,
          product_id,
          discount_price,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  }; */

  const downloadExcelSearchQuery = () => {
    const { customer_id, customer_name, total_discount, coupon_count } =
      getValues();

    const path = searchQueryHandler(
      "",
      "",
      currentSort.sortBy,
      currentSort.order,
      customer_id,
      customer_name,
      total_discount,
      coupon_count,
      1
    );

    return path.split("?")[1];
  };

  //search input field
  const InputFields = [
    {
      label: "Customer Id",
      name: "customer_id",
      required: false,
    },
    {
      label: "Customer Name",
      name: "customer_name",
      required: false,
    },
    {
      label: "Total Coupon Discount",
      name: "total_discount",
      required: false,
    },
    {
      label: "Total Coupon Count",
      name: "coupon_count",
      required: false,
    },
  ];

  //console.log(products, "products");

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Total Discount Applied"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <a
                      href={`${
                        BASEURL.PORT
                      }/${api_path}?${downloadExcelSearchQuery()}`}
                      className="btn btn-primary mr-2"
                    >
                      Export
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <Table
                      currentSort={currentSort}
                      //sortingHandler={sortingHandler}
                      mainData={data}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      onlyDate={{
                        created_at: "date",
                      }}
                      dontShowSort={[
                        "Customer Id",
                        "Customer Name",
                        "Total Coupon Discounts Applied",
                        "Total Orders with Discount",
                      ]}
                      renderAs={{
                        customer_id: (data) =>
                          data ? <div> #{data} </div> : <div>-</div>,
                        total_discount: (data) =>
                          data ? (
                            <div>
                              {" "}
                              {COMMON_CURRENCY} {data}{" "}
                            </div>
                          ) : (
                            <div>-</div>
                          ),
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={data.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
